import App, { Container } from 'next/app'
import Head from 'next/head'
import React from 'react'

import { ThemeProvider } from '@olxbr/design-system'

import { saveRedirectInfo } from '../util/redirect'
import Sentry from '../util/sentry'

if (process.browser && process.env.NODE_ENV === 'production') {
  Sentry.initialize(
    process.env.SENTRY_DSN
      || 'https://7496961c4d464a40a8ef3c76f9705b9d@sentry.olx.com.br/18',
  )
}

export default class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }
    return { pageProps }
  }

  constructor(props) {
    super(props)
    if (process.browser) {
      saveRedirectInfo()
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.componentDidCatch(error, errorInfo)
    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps } = this.props
    return (
      <ThemeProvider>
        <Container>
          <Head>
            <title>Minha conta | OLX</title>
          </Head>
          <Component {...pageProps} />
        </Container>
      </ThemeProvider>
    )
  }
}
