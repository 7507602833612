import * as Sentry from "@sentry/browser";

import { SentryWrapper } from "@olxbr/accounts-web-components";

const RELEASE = require("../../package.json").version;

class Wrapper extends SentryWrapper {
  static initialize(dsn) {
    Sentry.init({
      dsn,
      release: RELEASE,
      environment: process.env.OLX_ENV || "development",
    });
    SentryWrapper.initialize(Sentry);
  }
}

export default Wrapper;
